<template>
  <!-- Table Container Card -->
  <b-overlay :show="loading" rounded="sm">
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button variant="success" class="btn-icon mr-1" @click="get">
              <feather-icon icon="RefreshCwIcon" size="16" />
            </b-button>
            <b-button
              variant="gradient-info"
              @click="createUser"
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('create_user')
              "
            >
              <feather-icon icon="PlusIcon" size="16" />
              Nuevo usuario
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
          {{ data.item.surname }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('edit_user')
              "
            >
              <feather-icon
                v-if="data.item.active"
                @click="activeDeactivateUser(data.item.id, false)"
                icon="UserXIcon"
                size="16"
                class="text-danger cursor-pointer"
              />

              <feather-icon
                v-else
                @click="activeDeactivateUser(data.item.id, true)"
                icon="UserCheckIcon"
                size="16"
                class="text-success cursor-pointer"
              />
            </span>

            <feather-icon
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('edit_user')
              "
              @click="editUser(data.item)"
              icon="EditIcon"
              size="16"
              class="mx-2 text-success cursor-pointer"
            />
            <feather-icon
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('delete_user')
              "
              @click="deleteUser(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="text-danger cursor-pointer"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted" v-if="totalRegisters > 0"
              >Mostrando {{ fromRegister }} a {{ toRegister }} de
              {{ totalRegisters }} registros
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="items.length !== totalRegisters"
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <!-- Right sidebar -->
      <b-sidebar
        v-model="showUserModal"
        id="sidebar-right"
        :title="(flagUpdateAction ? 'Actualizar ' : 'Nuevo ') + 'usuario'"
        right
        shadow
      >
        <div class="p-2">
          <!-- form -->
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(storeUser)">
              <!-- name -->
              <b-form-group label="Nombre" label-for="name">
                <ValidationProvider
                  name="Nombre"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input id="name" v-model="user.name" name="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- surname -->
              <b-form-group label="Apellido" label-for="surname">
                <ValidationProvider
                  name="Apellido"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    id="surname"
                    v-model="user.surname"
                    name="surname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="email">
                <ValidationProvider
                  name="Correo"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-form-input id="email" v-model="user.email" name="email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- password -->
              <b-form-group label="Contraseña" label-for="password">
                <ValidationProvider
                  name="Contraseña"
                  :rules="flagUpdateAction ? '' : 'required'"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    id="password"
                    v-model="user.password"
                    type="password"
                    name="Contraseña"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- phone -->
              <b-form-group label="Teléfono" label-for="phone">
                <b-form-input id="phone" v-model="user.phone" name="phone" />
              </b-form-group>

              <!-- Roles -->
              <b-form-group label="Rol" label-for="role">
                <ValidationProvider
                  name="Rol"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-select
                    value-field="key"
                    text-field="name"
                    v-model="user.role"
                    :options="roles"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- submit button -->
              <div class="text-center">
                <b-overlay
                  :show="loading_btn"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    :disabled="loading_btn"
                    type="submit"
                    variant="primary"
                  >
                    <feather-icon icon="SaveIcon" size="16" />
                    Guardar
                  </b-button>
                </b-overlay>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-sidebar>
    </b-card>
  </b-overlay>
</template>

<script>
//Vuex
import { createNamespacedHelpers } from "vuex-composition-helpers";
//const { useActions, useState } = createNamespacedHelpers('app') // specific module name

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BModal,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BSidebar,
  BOverlay
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onMounted, ref, watch } from "@vue/composition-api";
import useInvoicesList from "./apps/invoice/invoice-list/useInvoiceList";
import { useToast } from "vue-toastification/composition";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//Validation forms
import { ValidationProvider, ValidationObserver } from "vee-validate";

//Services
import userService from "@/services/user.service";
import { mapState } from "vuex";
//Helpers
import {
  confirmAlert,
  perPageBaseOptions,
  perPageBaseRegisters
} from "@/helpers";

//import useVuelidate from '@vuelidate/core'
//import { required, email } from '@vuelidate/validators'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BModal,
    BFormGroup,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  setup() {
    const toast = useToast();

    //Data (properties)
    const loading = ref(true);
    const items = ref([]);
    const tableColumns = ref([
      //   { key: 'code', label: 'Código', sortable: true },
      { key: "name", label: "Nombres", sortable: true },
      {
        key: "email",
        label: "Correo",
        sortable: true
      },
      { key: "phone", label: "Teléfono", sortable: true },
      /*{ key: 'role', label: 'Rol', sortable: true },*/
      { key: "actions", label: "Acciones" }
    ]);

    const showUserModal = ref(false);
    const flagUpdateAction = ref(false);

    const roles = ref([]);

    const baseUser = {
      name: "",
      surname: "",
      email: "",
      password: "",
      phone: "",
      role: "admin"
    };

    const user = ref(JSON.parse(JSON.stringify(baseUser)));

    //Pagination
    const currentPage = ref(1);
    const lastPage = ref(1);
    const totalRegisters = ref(1);
    //const perPage = ref(10)
    const search = ref("");
    const fromRegister = ref(1);
    const toRegister = ref(perPageBaseRegisters);
    const perPage = ref(perPageBaseRegisters);
    const perPageOptions = ref(perPageBaseOptions);
    const loading_btn = ref(false);

    const getRoles = async () => {
      const { data: res } = await userService.getRoles();
      roles.value = res.data;
    };

    const createUser = () => {
      user.value = JSON.parse(JSON.stringify(baseUser));
      showUserModal.value = true;
      flagUpdateAction.value = false;
    };
    const editUser = item => {
      user.value = JSON.parse(JSON.stringify(item));
      showUserModal.value = true;
      flagUpdateAction.value = true;
    };

    const deleteUser = async user_id => {
      try {
        const confirm = await confirmAlert(
          "Eliminar usuario",
          "Vas a eliminar el usuario deseas continuar"
        );
        if (!confirm.isConfirmed) return;

        const { data: res } = await userService.deleteUser({
          id: user_id
        });

        const index = items.value.findIndex(i => i.id === user_id);
        items.value.splice(index, 1);
        //items.value = JSON.parse(JSON.stringify(items.value))
      } catch (error) {
        console.log(error);
      }
    };

    const activeDeactivateUser = async (user_id, active) => {
      try {
        const confirm = await confirmAlert(
          active ? "Activar usuario" : "Desactivar usuario",
          active
            ? "Vas a activar el usuario deseas continuar"
            : "Vas a desactivar el usuario deseas continuar"
        );
        if (!confirm.isConfirmed) return;

        const { data: res } = await userService.activeDeactivateUser({
          id: user_id
        });

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: "CheckIcon",
              variant: "warning"
            }
          });
          return;
        }

        const index = items.value.findIndex(i => i.id === res.data.id);
        items.value[index] = res.data;
        items.value = JSON.parse(JSON.stringify(items.value));
        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: "CheckIcon",
            variant: "success"
          }
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "CheckIcon",
            variant: "warning"
          }
        });
      }
    };

    const storeUser = async () => {
      try {
        loading_btn.value = true;
        const { data: res } = await (flagUpdateAction.value
          ? userService.updateUser(user.value)
          : userService.storeUser(user.value));
        loading_btn.value = false;
        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: "CheckIcon",
              variant: "warning"
            }
          });
          return;
        }

        showUserModal.value = false;
        //If store
        if (!flagUpdateAction.value) items.value.unshift(res.data);
        //If update
        if (flagUpdateAction.value) {
          const index = items.value.findIndex(i => i.id === res.data.id);
          items.value[index] = res.data;
          items.value = JSON.parse(JSON.stringify(items.value));
        }

        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: "CheckIcon",
            variant: "success"
          }
        });
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "CheckIcon",
            variant: "warning"
          }
        });
      }
      loading_btn.value = false;
    };

    const get = async (page = 1, searchQuery = {}) => {
      loading.value = true;
      searchQuery.perPage = perPage.value;
      const { data: res } = await userService.getUsers(page, searchQuery);
      items.value = res.data.data;
      loading.value = false;

      //Pagination
      //perPage.value = res.data.per_page
      //  currentPage.value = res.current_page
      lastPage.value = res.data.last_page;
      toRegister.value = res.data.to;
      fromRegister.value = res.data.from;

      totalRegisters.value = res.data.total;
    };

    //Wachers
    watch(currentPage, (currentPage, prevCurrentPage) => {
      get(currentPage, {
        term: search.value
      });
    });

    watch(perPage, currentPerPage => {
      toRegister.value = currentPerPage;
      get(1, {
        term: search.value
      });
    });

    watch(search, async (search, prevSearch) => {
      if (currentPage.value === 1) {
        await get(currentPage.value, {
          term: search
        });
      } else {
        currentPage.value = 1;
      }
    });

    //Mounted
    onMounted(() => {
      get();
      getRoles();
    });

    const {
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveUserAvatarVariant
    } = useInvoicesList();

    return {
      tableColumns,
      perPage,
      currentPage,
      totalRegisters,
      perPageOptions,
      search,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveUserAvatarVariant,
      items,
      fromRegister,
      toRegister,
      user,
      storeUser,
      showUserModal,
      editUser,
      createUser,
      deleteUser,
      flagUpdateAction,
      roles,
      activeDeactivateUser,
      loading_btn,
      loading,
      get
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
