var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"9"}},[_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-button',{staticClass:"btn-icon mr-1",attrs:{"variant":"success"},on:{"click":_vm.get}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon","size":"16"}})],1),(
              (_vm.user_state && _vm.user_state.role == 'admin') ||
                _vm.permissions.includes('create_user')
            )?_c('b-button',{attrs:{"variant":"gradient-info"},on:{"click":_vm.createUser}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" Nuevo usuario ")],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1)],1),_c('b-table',{ref:"refInvoiceListTable",staticClass:"position-relative",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No se encontraron registros","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.surname)+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[(
              (_vm.user_state && _vm.user_state.role == 'admin') ||
                _vm.permissions.includes('edit_user')
            )?_c('span',[(data.item.active)?_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"UserXIcon","size":"16"},on:{"click":function($event){return _vm.activeDeactivateUser(data.item.id, false)}}}):_c('feather-icon',{staticClass:"text-success cursor-pointer",attrs:{"icon":"UserCheckIcon","size":"16"},on:{"click":function($event){return _vm.activeDeactivateUser(data.item.id, true)}}})],1):_vm._e(),(
              (_vm.user_state && _vm.user_state.role == 'admin') ||
                _vm.permissions.includes('edit_user')
            )?_c('feather-icon',{staticClass:"mx-2 text-success cursor-pointer",attrs:{"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.editUser(data.item)}}}):_vm._e(),(
              (_vm.user_state && _vm.user_state.role == 'admin') ||
                _vm.permissions.includes('delete_user')
            )?_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.deleteUser(data.item.id)}}}):_vm._e()],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[(_vm.totalRegisters > 0)?_c('span',{staticClass:"text-muted"},[_vm._v("Mostrando "+_vm._s(_vm.fromRegister)+" a "+_vm._s(_vm.toRegister)+" de "+_vm._s(_vm.totalRegisters)+" registros ")]):_vm._e()]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.items.length !== _vm.totalRegisters)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalRegisters,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1),_c('b-sidebar',{attrs:{"id":"sidebar-right","title":(_vm.flagUpdateAction ? 'Actualizar ' : 'Nuevo ') + 'usuario',"right":"","shadow":""},model:{value:(_vm.showUserModal),callback:function ($$v) {_vm.showUserModal=$$v},expression:"showUserModal"}},[_c('div',{staticClass:"p-2"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.storeUser)}}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Apellido","label-for":"surname"}},[_c('ValidationProvider',{attrs:{"name":"Apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surname","name":"surname"},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Contraseña","label-for":"password"}},[_c('ValidationProvider',{attrs:{"name":"Contraseña","rules":_vm.flagUpdateAction ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","name":"Contraseña"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","name":"phone"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('b-form-group',{attrs:{"label":"Rol","label-for":"role"}},[_c('ValidationProvider',{attrs:{"name":"Rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"value-field":"key","text-field":"name","options":_vm.roles},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.loading_btn,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"disabled":_vm.loading_btn,"type":"submit","variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"16"}}),_vm._v(" Guardar ")],1)],1)],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }